<template>
  <div ref="sandbox" :style="computedStyle" class="sandbox">
    <div class="ace-label">
      <button
        @click="data.tab = 'html'"
        class="tab"
        :class="{ active: data.tab == 'html' }"
      >
        HTML
      </button>
      <button
        @click="data.tab = 'css'"
        class="tab"
        :class="{ active: data.tab == 'css' }"
      >
        CSS
      </button>
      <button
        @click="data.tab = 'javascript'"
        class="tab"
        :class="{ active: data.tab == 'javascript' }"
      >
        JS
      </button>

      <button
        @click="handleResult"
        class="tab"
        :class="{ active: data.tab == 'result' }"
      >
        Result
      </button>
    </div>
    <div v-show="data.tab == 'html'" class="cell">
      <v-ace-editor
        class="ace-html"
        v-model:value="data.html"
        @input="handleUpdate"
        @init="editorInit"
        lang="html"
        :options="{
          fontSize: 16,
          mode: 'ace/mode/html',
          displayIndentGuides: true,
          highlightGutterLine: false,
          showPrintMargin: false,
          useSoftTabs: true,
          useWorker: true,
        }"
        style="height: 100%"
      />
    </div>

    <div v-show="data.tab == 'javascript'" class="cell">
      <v-ace-editor
        class="ace-js"
        v-model:value="data.js"
        @input="handleUpdate"
        @init="editorInit"
        lang="javascript"
        :options="{
          fontSize: 16,
          mode: 'ace/mode/javascript',
          displayIndentGuides: true,
          highlightGutterLine: false,
          showPrintMargin: false,
          useSoftTabs: true,
          useWorker: true,
        }"
        style="height: 100%"
      />
    </div>

    <div v-show="data.tab == 'css'" class="cell">
      <v-ace-editor
        class="ace-css"
        v-model:value="data.css"
        @input="handleUpdate"
        @init="editorInit"
        lang="css"
        :options="{
          fontSize: 16,
          mode: 'ace/mode/css',
          displayIndentGuides: true,
          highlightGutterLine: false,
          showPrintMargin: false,
          useSoftTabs: true,
          useWorker: true,
        }"
        style="height: 100%"
      />
    </div>

    <div
      v-show="data.tab == 'result'"
      :style="{ height: data.rightBottomHeight }"
      class="cell cell-result"
    >
      <div class="result-frame">
        <iframe
          :style="{ background: data.srcdoc ? 'var(--bb-foreground)' : 'none' }"
          allowfullscreen
          scrolling="yes"
          :srcdoc="data.srcdoc"
          id="frame"
        >
        </iframe>
      </div>
      <!--<div class="cell-result-bottom">
        <button :disabled="data.submitting" id="run" @click="runContent">
          run
        </button>

        <button :disabled="data.submitting" 
          v-if="$route.path.match('/sandbox')" id="save" @click="handleSave">
          save
        </button>
      </div>-->
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import router from "@/router/index";
import { VAceEditor } from "vue3-ace-editor";
import {
  defineEmits,
  ref,
  onMounted,
  reactive,
  computed,
  defineProps,
} from "vue";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-chaos";
import "ace-builds/src-noconflict/theme-dracula";

const emit = defineEmits(["update:value"]);
const sandbox = ref(null);
const BASE_URL = process.env.VUE_APP_BASEURL;

const editorInit = (e) => {
  e.setTheme("ace/theme/dracula");
};

onMounted(async () => {
  let slug = router.currentRoute.value.params.slug;
  let currentValue = JSON.parse(localStorage.getItem("bb_current_value"));

  if (slug) {
    data.submitting = true;

    var resp = await axios.get(BASE_URL + "/brainbits?slug=" + slug);
    var bit = resp.data[0];

    console.log(bit);
    data.html = bit.html;
    data.css = bit.css;
    data.js = bit.js;
    data.slug = bit.slug;
    data.submitting = false;
  } else if (currentValue) {
    data.html = currentValue.html;
    data.css = currentValue.css;
    data.js = currentValue.js;
  }

  setInterval(runContent, 30000);
});

const handleResult = (e) => {
  e.preventDefault();

  data.tab = "result";
  runContent();
};

const handleUpdate = () => {
  let value = { html: data.html, css: data.css, js: data.js };
  emit("update:value", value);

  localStorage.setItem("bb_current_value", JSON.stringify(value));
};

//const handleSave = async () => {
//  data.submitting = true
//  var bit = await axios.post(BASE_URL + "/brainbits",
//    {slug: data.slug, html: data.html, css: data.css, js: data.js })

//  router.push("/sandbox/" + bit.data.slug)

//  data.submitting = false
//}

const props = defineProps({
  width: {
    type: String,
    default: "50vw",
  },

  disableScroll: {
    type: Boolean,
    default: false,
  },
});

const data = reactive({
  tab: "html",
  srcdoc: null,
  html: "",
  js: "",
  css: "",
  slug: null,
  submitting: false,
});

const getContent = () => {
  /* eslint-disable */
  return `
  <html>
    <body>
      ${data.html}
    </body>
    <script>
      ${data.js}
    <\/script>
    <style>
      ${data.css}
    </style>
  </html>`;
};

const runContent = () => {
  if (data.html) {
    data.srcdoc = getContent();
  }
};

const computedStyle = computed(() => {
  return { width: props.width, userSelect: props.disableScroll };
});
</script>

<style scoped>
.sandbox {
  min-width: 120px;
  box-sizing: border-box;
  background: var(--bb-background);
  color: var(--bb-foreground);
  margin-top: 65px;
}

.sandbox-left {
  height: 100%;
  float: left;
}

.sandbox-right {
  height: 100%;
  float: left;
}

.cell {
  overflow: hidden;
  padding: 12px;
  height: calc(100% - 36px);
  box-sizing: border-box;
  background: var(--bb-background);
}

.cell-bottom {
  display: grid;
  grid-template-rows: 1fr auto;
}

.cell-result {
  display: grid;
  grid-template-rows: 1fr auto;
}

.result-frame {
  position: relative;
  box-sizing: border-box;
  background: var(--bb-background);
}

label {
  padding-left: 8px;
  padding-top: 8px;
  font-weight: bold;
  font-size: 12px;
  color: #44475a;
}

iframe {
  height: 100%;
  width: 100%;
  margin: 0;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
}

.ace_editor {
  height: calc(100% - 24px);
}

.ace-label {
  position: relative;
  width: 100%;
  font-size: 12px;
  z-index: 2;
  background: var(--bb-current-line);
  box-sizing: border-box;
  color: #5b5b5b;
  display: flex;
  align-items: flex-end;
}

.bottom {
  text-align: right;
}

.cell-result-bottom {
  box-sizing: border-box;
  text-align: right;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 60px));
  justify-content: end;
  grid-column-gap: 8px;
  align-content: center;
  padding: 4px;
  background: var(--bb-background);
}

.cell-result-bottom button {
  justify-self: end;
  margin-top: 0;
}

button {
  font-weight: bold;
  border: 2px solid var(--bb-orange);
  border-radius: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 12px;
  background: var(--bb-orange);
  text-transform: capitalize;
  color: var(--bb-background);
  height: 28px;
}

.tab {
  border: none;
  height: 26px;
  border-bottom: none;
  border-radius: 0px;
  background: var(--bb-current-line);
  color: var(--bb-background);
  margin: 0px 2px;
}

.tab.active,
.tab.active:hover {
  opacity: 1;
  height: 28px;
  top: 1px;
  position: relative;
  background: var(--bb-background);
  color: var(--bb-yellow);
  border-radius: 0px;
}
</style>
