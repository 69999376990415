<template>
  <div @mousemove="handleResize" class="main-view">
    <Content
      :course="$route.params.course"
      :lesson_number="$route.params.number"
      :disable-scroll="data.resizing"
      :width="data.leftWidth"
    />

    <div
      @mousedown="data.resizing = !data.resizing"
      @mouseup="data.resizing = !data.resizing"
      class="handle"
    ></div>

    <TabbedSandbox
      v-model:value="data.input"
      :disable-scroll="data.resizing"
      :width="data.rightWidth"
    />
  </div>
</template>

<script setup>
import { reactive } from "vue";
import Content from "@/components/Content";
import TabbedSandbox from "@/components/TabbedSandbox";

const data = reactive({
  input: {},
  leftWidth: null,
  rightLeft: null,
  resizing: false,
});

const handleResize = (e) => {
  if (data.resizing) {
    data.leftWidth = e.clientX + "px";
    data.rightWidth = window.innerWidth - e.clientX + "px";
  }
};
</script>

<style scoped>
.main-view {
  height: 100%;
  display: grid;
  grid-template: "content handle sandbox" 1fr / 1fr min-content 1fr;
  overflow: hidden;
  background: linear-gradient(45deg, var(--cyan-500), var(--cyan-600));
}

.content {
  margin-top: 56px;
  min-width: 120px;
  box-sizing: border-box;
  padding: 32px;
  grid-area: content;
  overflow: scroll;
  background: var(--bb-foreground);
}

.handle {
  position: relative;
  cursor: ew-resize;
  grid-area: handle;
  width: 1px;
  height: 100%;
}

.handle:after {
  position: absolute;
  content: "";
  display: block;
  height: 100%;
  width: 8px;
  left: -3px;
  z-index: 10;
}
</style>
