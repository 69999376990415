<template>
  <div @mousemove="handleResize" class="main-view">
    <Codebox
      v-model:value="data.input"
      v-model:mode="data.mode"
      :run-handler="handleRun"
      :disable-scroll="data.resizing"
      :width="data.leftWidth"
    />

    <div
      @mousedown="data.resizing = !data.resizing"
      @mouseup="data.resizing = !data.resizing"
      class="handle"
    ></div>

    <div
      class="bb-sandbox-go-result"
      :style="{ userSelect: data.resizing, width: data.rightWidth }"
    >
      <div class="bb-sandbox-go-result-pane">
        <vue3-markdown-it
          :html="false"
          :linkify="true"
          :typographer="true"
          :source="computedResult"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";

const GO_URL = process.env.VUE_APP_GOURL;

import { computed, reactive } from "vue";
import Codebox from "@/components/Codebox";

const data = reactive({
  input: "",
  mode: "golang",
  running: false,
  result: "",
  leftWidth: null,
  rightLeft: null,
  resizing: false,
});

const computedResult = computed(() => {
  return data.running
    ? "```shell\nrunning...\n```"
    : "```shell\n" + data.result + "\n```";
});

const handleResize = (e) => {
  if (data.resizing) {
    data.leftWidth = e.clientX + "px";
    data.rightWidth = window.innerWidth - e.clientX + "px";
  }
};

const handleRun = () => {
  data.running = true;
  axios
    .post(GO_URL, {
      payload: data.input,
      lang: data.mode,
    })
    .then((resp) => {
      console.log(JSON.stringify(resp));
      data.result = resp.data;
      data.running = false;
    })
    .catch((e) => {
      data.result = JSON.stringify(e.response.data);
      data.running = false;
    });
};
</script>

<style scoped>
.main-view {
  height: 100%;
  display: grid;
  grid-template: "sandbox handle content" 1fr / 1fr min-content 1fr;
  overflow: hidden;
  background: linear-gradient(45deg, var(--cyan-500), var(--cyan-600));
}

.content {
  margin-top: 56px;
  min-width: 120px;
  height: 100%;
  box-sizing: border-box;
  padding: 32px;
  grid-area: content;
  overflow-y: scroll;
  background: white;
}

.handle {
  cursor: ew-resize;
  grid-area: handle;
  height: 100%;
  width: 1px;
}

.handle:before {
  position: relative;
  content: "";
  display: block;
  height: 100%;
  width: 8px;
  right: -3px;
  z-index: 10;
}

.toolbar {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid var(--bb-current-line);
  background: var(--bb-background);
}

.bb-sandbox-go-result {
  margin-top: 65px;
  color: var(--bb-green);
  background: var(--bb-background);
  overflow: scroll;
}

pre,
code {
  white-space: pre-line;
}
</style>
