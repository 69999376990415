<template>
  <div class="sockbox-view">
    <div class="tos">
      <Panel>
        <template #header>Terms of service</template>
        By using the BITBRAIN demonstration server, you agree that:
        <Divider />
        <ul>
          <li>
            Access to this service may be revoked at any time for any reason
          </li>
          <li>Access to this service is solely provided to evaluate Linux</li>
          <li>
            Your IP address, access time and activity on the test server may be
            recorded
          </li>
          Any abuse of this service may lead to a ban or other applicable
          actions
          <li>You will not do anything stupid or illegal</li>
        </ul>
      </Panel>

      <div class="current-sessions">
        <Chip icon="pi pi-server" :label="`Current sessions: ${data.count}/10`">
        </Chip>
      </div>
    </div>

    <div :style="terminalStyle" class="terminal-container">
      <div class="ws-status">
        <Button
          class="p-button-sm"
          :disabled="
            data.socket &&
            (data.socket.readyState == 0 || data.socket.readyState == 2)
          "
          @click="handleConnect"
        >
          {{
            data.connected
              ? "Disconnect"
              : data.id
              ? "Connect to " + data.id
              : "New Session"
          }}
        </Button>
        <div
          v-if="data.socket"
          class="status-ball"
          :class="{
            connecting: data.socket.readyState == 0,
            open: data.socket.readyState == 1,
            closing: data.socket.readyState == 2,
            closed: data.socket.readyState == 3,
          }"
        ></div>
      </div>
      <button
        v-if="data.connected"
        class="bb-fullscreen"
        @click="handleFullscreen"
      ></button>
      <div v-if="data.id">
        <div @click="shareClick" class="share-session">{{ shareLink }}</div>
      </div>
      <div id="terminal"></div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router/index";
import axios from "axios";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";

const SOCK_URL = process.env.VUE_APP_SOCKURL;

import { computed, onMounted, reactive } from "vue";
//const states = ["CONNECTING", "OPEN", "CLOSING", "CLOSED"];

const terminalStyle = computed(() => {
  return {
    height: data.fullscreen ? "100vh" : "600px",
    width: data.fullscreen ? "100vw" : "800px",
    top: data.fullscreen ? 0 : "unset",
    left: data.fullscreen ? 0 : "unset",
    zIndex: data.fullscreen ? 100 : "unset",
    position: data.fullscreen ? "absolute" : "relative",
    boxSizing: "border-box",
  };
});

const fitAddon = new FitAddon();

const handleFullscreen = () => {
  data.fullscreen = !data.fullscreen;
  setTimeout(() => {
    fitAddon?.fit();
  }, 300);
};

const shareLink = computed(() => {
  if (data.id) {
    return window.location + "/" + data.id;
  }
  return "";
});

const shareClick = (e) => {
  navigator.clipboard.writeText(e.target.innerHTML);
  alert("Copied session link: " + e.target.innerHTML);
};

const data = reactive({
  input: "",
  fullscreen: false,
  id: null,
  connected: false,
  count: "0",
  count_url: null,
  term: new Terminal({
    convertEol: true,
    fontSize: "12",
    fontFamily: "Courier New",
    rendererType: "dom",
  }),
  socket: null,
});

const setupSocket = () => {
  if (router.currentRoute.value.params.id) {
    data.id = router.currentRoute.value.params.id;
  }

  let url = data.id ? SOCK_URL + "/" + data.id : SOCK_URL;

  data.socket = new WebSocket(url);

  data.socket.onopen = function () {
    data.connected = true;
    data.term.write("creating container...\n");
    data.socket.send(
      `stty columns ${data.term.cols} rows ${data.term.rows - 1}\n`
    );
  };

  data.socket.onclose = function () {
    data.connected = false;
    data.id = null;
    data.term.clear();
    data.term.reset();
  };

  data.socket.onmessage = function (e) {
    if (e.data.match(/^{"id":.*}$/)) {
      data.id = JSON.parse(e.data).id;
    } else {
      data.term.write(e.data);
    }
  };
};

const handleConnect = () => {
  data.connected ? data.socket.close() : setupSocket();
  updateCount();
};

const updateCount = () => {
  axios.get(data.count_url).then((resp) => (data.count = resp.data));
};

onMounted(async () => {
  data.id = router.currentRoute.value.params.id;
  data.count_url = SOCK_URL.replace(/^ws/, "http").replace(/sh$/, "count");

  updateCount();
  data.term.open(document.getElementById("terminal"));
  data.term.loadAddon(fitAddon);
  fitAddon?.fit();

  setInterval(() => {
    updateCount();
  }, 10000);

  data.term.onKey((e) => {
    console.log(e.key);
    if (e.key == "\r") {
      data.socket.send("\n");
    } else {
      data.socket.send(e.key);
    }
  });

  data.term.onResize(() => {
    //data.socket.send("\x01" + `:width -d ${data.term.cols - 1}\n`);
    //data.socket.send("\x01" + `:height -d ${data.term.rows - 1}\n`);
    //data.socket.send(
    //  `COLUMNS=${data.term.cols};LINES=${
    //    data.term.rows - 1
    //  };export COLUMNS LINES\n`
    //);
    data.socket.send(
      `stty columns ${data.term.cols} rows ${data.term.rows - 1}\n`
    );
  });

  window.addEventListener("resize", () => {
    fitAddon?.fit();
  });
});
</script>

<style scoped>
.tos {
  margin: 56px auto 0 auto;
  padding: 24px 0 0;
  width: fit-content;
}

#terminal {
  background: black;
  width: 100%;
  height: 90%;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 0 0 6px 6px;
}

.terminal-container {
  position: relative;
  width: 800px;
  margin: auto;
  margin-bottom: 65px;
  height: 600px;
}

@media screen and (max-width: 640px) {
  .terminal-container {
    width: 100vw !important;
    border: none;
    /*height: 100%;*/
    margin: 0;
    padding: 0;
    border: none;
    align-self: self-end;
  }
}

/*:deep(.xterm-screen) {
  position: absolute;
}
:deep(canvas) {
  position: absolute !important;
  top: 0 !important;
}

:deep(textarea) {
  outline: none;
}*/

.sockbox-view {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(45deg, var(--cyan-500), var(--cyan-600));
}

.bb-sandbox-go-result {
  margin-top: 56px;
  color: var(--bb-green);
  overflow: scroll;
}

.ws-status {
  padding: 0.4em 1em 0.4em 1em;
  border-radius: 6px 6px 0 0;

  text-align: right;
  color: var(--bb-green);
  gap: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #dee2e6;
  padding: 1.25rem;
  background: #f8f9fa;
  color: #343a40;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.status-ball.connecting {
  background: var(--bb-yellow);
}

.status-ball.open {
  background: var(--bb-green);
}

.status-ball.closing {
  background: var(--bb-orange);
}

.status-ball.closed {
  background: var(--bb-red);
}

.status-ball {
  height: 0.8em;
  width: 0.8em;
  border-radius: 1em;
  border: 1px solid var(--bb-background);
}

.current-sessions {
  text-align: center;
  color: var(--bb-comment);
  padding: 1em;
  box-sizing: border-box;
  overflow-wrap: anywhere;
}

.share-session::after {
  content: "➦";
  margin-left: 0.25em;
  margin-top: 1em;
}

.share-session {
  font-size: 0.75em;
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
  color: var(--bb-purple);

  padding: 0.25em;
  background: var(--bb-background-darker);
}

.bb-fullscreen {
  padding: 0;
  border-radius: 0;
  background: none;
  top: 2px;
  left: 2px;
  float: left;
  position: relative;
  width: 14px;
  height: 14px;
  border: 2px solid var(--bb-purple);
}

.bb-fullscreen:after {
  content: "";
  position: absolute;
  background: var(--bb-background-darker);
  height: 6px;
  top: 2px;
  left: -2px;
  width: 14px;
}

.bb-fullscreen:before {
  content: "";
  position: absolute;
  background: var(--bb-background-darker);
  width: 6px;
  top: -2px;
  left: 2px;
  height: 14px;
}
</style>
