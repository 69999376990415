<template>
  <div ref="sandbox" :style="computedStyle" class="sandbox">
    <div class="toolbar">
      <Button
        @click="handleVim"
        class="vim p-button-sm"
        :class="{ active: data.vimModeEnabled }"
      >
        VIM
      </Button>

      <Button @click="handleSave" class="vim p-button-sm">Save</Button>
      <Button class="vim p-button-sm">
        <label class="import-button" for="import">Open</label>
        <input id="import" type="file" accept=".md" @change="handleOpen" />
      </Button>
    </div>
    <v-ace-editor
      class="ace-html"
      v-model:value="data.markdown"
      @input="handleUpdate"
      @init="editorInit"
      lang="markdown"
      :options="{
        fontSize: 14,
        mode: 'ace/mode/markdown',
        displayIndentGuides: true,
        useSoftTabs: true,
        useWorker: true,
      }"
      style="height: 100%"
    />
  </div>
</template>

<script setup>
import { VAceEditor } from "vue3-ace-editor";
import {
  onMounted,
  ref,
  reactive,
  computed,
  defineProps,
  defineEmits,
} from "vue";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/keybinding-vim";

const sandbox = ref(null);
const editorInit = (e) => {
  data.editor = e;
  data.editor.setTheme("ace/theme/dracula");
};

const handleSave = (e) => {
  e.preventDefault();
  let dataStr = data.markdown;
  let dataUri =
    "data:application/markdown;charset=utf-8," + encodeURIComponent(dataStr);
  let exportFileDefaultName = "markdown.md";

  let linkElement = document.createElement("a");
  linkElement.setAttribute("href", dataUri);
  linkElement.setAttribute("download", exportFileDefaultName);
  linkElement.click();
};

const handleOpen = (e) => {
  e.preventDefault();
  let reader = new FileReader();
  let fileList = event.target.files;

  reader.onload = () => {
    data.markdown = reader.result;
  };

  reader.readAsText(fileList[0]);
};

const handleVim = (e) => {
  e.preventDefault();
  data.vimModeEnabled = !data.vimModeEnabled;

  if (data.vimModeEnabled) {
    data.editor.setKeyboardHandler("ace/keyboard/vim");
  } else {
    data.editor.setKeyboardHandler("");
  }

  console.log(data.editor);
};

const emit = defineEmits(["update:value"]);

const handleUpdate = () => {
  emit("update:value", data.markdown);
  localStorage.setItem("bb_markdown_value", data.markdown);
};

const props = defineProps({
  width: {
    type: String,
    default: "50vw",
  },

  disableScroll: {
    type: Boolean,
    default: false,
  },
});

const data = reactive({
  markdown: "",
  editor: null,
  vimModeEnabled: false,
});

const computedStyle = computed(() => {
  return { width: props.width, userSelect: props.disableScroll };
});

onMounted(() => {
  let currentData = localStorage.getItem("bb_markdown_value");

  if (currentData) {
    data.markdown = currentData;
  }
});
</script>

<style scoped>
:global(.hljs:before) {
  position: relative;
  top: -18px;
  float: right;
  left: 14px;
  font-size: 12px;
}

:global(.hljs.language-css:before) {
  content: "css";
}

:global(.hljs.language-html:before) {
  content: "html";
}

:global(.hljs.language-javascript:before) {
  content: "javascript";
}

:global(.md-table) {
  margin: auto;
  height: 200px;
  width: 200px;
  background: var(--bb-background);
  color: var(--bb-foreground);
  display: grid;
  grid-template-rows: min-content 1fr;
}

:global(.md-tab) {
  padding: 5px;
  width: 50px;
  text-align: center;
}

:global(.md-active) {
  color: var(--bb-yellow);
  background: var(--bb-background);
}

:global(.md-header) {
  width: 200px;
  height: 24px;
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  background: var(--bb-current-line);
  color: #0d0d0d;
  font-weight: bold;
}

:global(.md-cell) {
  text-align: center;
  border: 0.5px solid var(--bb-current-line);
  text-align: right;
  padding: 2px;
  font-size: 14px;
}

:global(code) {
  background: var(--bb-black);
  color: var(--bb-beige);
}

:global(p > a) {
  text-decoration: underline;
}

:global(button.example) {
  border: none;
  background: var(--bb-orange);
  padding: 2px;
}

.vim {
  border: none;
  background: var(--bb-yellow);
}

.vim.active {
  background: var(--bb-green) !important;
  color: var(--surface-900) !important;
}

.sandbox {
  box-sizing: border-box;
  background: var(--bb-black);
  color: white;
  margin-top: 65px;
  display: grid;
  grid-template-rows: min-content 1fr;
}

.ace_editor {
  height: calc(100% - 24px);
}

input[type="file"] {
  display: none;
  position: absolute;
  z-index: -1;
  top: 10px;
  left: 8px;
  font-size: 17px;
  color: #b8b8b8;
}

.toolbar {
  padding: 4px;
  text-align: right;
  background: var(--bb-background);
}

button {
  margin: 2px;
}
</style>
