import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";

import PrimeVue from "primevue/config";
import Chip from "primevue/chip";
import Message from "primevue/message";
import MenuBar from "primevue/menubar";
import Button from "primevue/button";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import Panel from "primevue/panel";
import Badge from "primevue/badge";
import TabMenu from "primevue/tabmenu";

import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import VueMarkdownIt from "vue3-markdown-it";
import VueHighlightJS from "vue3-highlightjs";
import "highlight.js/styles/dracula.css";

import * as ace from "ace-builds"; // ace module ..
import workerCssUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-css.js";
import workerJSUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-javascript.js";
import workerHtmlUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-html.js";

ace.config.set("basePath", "");
ace.config.set("modePath", "");
ace.config.set("themePath", "");

ace.config.setModuleUrl("ace/mode/css_worker", workerCssUrl);
ace.config.setModuleUrl("ace/mode/html_worker", workerHtmlUrl);
ace.config.setModuleUrl("ace/mode/javascript_worker", workerJSUrl);

const app = createApp(App);
app.use(VueMarkdownIt).use(VueHighlightJS).use(router).use(PrimeVue);
app.component("Message", Message);
app.component("MenuBar", MenuBar);
app.component("Chip", Chip);
app.component("Card", Card);
app.component("Badge", Badge);
app.component("Button", Button);
app.component("Divider", Divider);
app.component("Dropdown", Dropdown);
app.component("Panel", Panel);
app.component("TabMenu", TabMenu);
app.mount("#app");
