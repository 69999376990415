<template>
  <div class="bb-courses">
    <div v-if="data.courses" class="bb-courses-list">
      <courses-list-item :course="course" v-for="course in data.courses" :key="course"/>
    </div>
  </div>
</template>

<script setup>
import axios from "axios"
import CoursesListItem from "@/components/CoursesListItem"
import { reactive, onMounted } from "vue"

const BASE_URL = process.env.VUE_APP_BASEURL

const data = reactive({
  courses: null
})

onMounted(async ()=>{
  let courses = await axios.get(BASE_URL + "/courses")

  data.courses = courses.data
})

</script>

<style scoped>

.bb-courses {
  padding: 8px;
  text-align: center;
}

.bb-courses-list {
  margin: auto;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 400px));
  grid-gap: 8px;
}

@media screen and (max-width: 640px) {
  .bb-courses-list {
    grid-template-columns: repeat(auto-fit, minmax(100%, 400px));
  }
}
</style>

