<template>
  <div class="bb-course-overview">
    <div v-if="data.course">
      <div v-if="data.course.cover_img" class="bb-course-cover">
        <img :src="getImgUrl(data.course)" />
        {{ data.course.cover_img }}
      </div>
      <div class="bb-course-body">
        <div class="bb-course-title">
          <h1>Course: {{ data.course.title }}</h1>
        </div>
        <div class="bb-course-title">
          Description: {{ data.course.description }}
        </div>

        <div class="bb-course-lessons-list-container">
          <lessons-list :lessons="data.course.lessons" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router/index";
import LessonsList from "@/components/LessonsList";

import "highlight.js/styles/dracula.css";
import axios from "axios";
import { reactive, onMounted } from "vue";

const BASE_URL = process.env.VUE_APP_BASEURL;

const data = reactive({
  course: {
    lessons: [],
  },
});

const getImgUrl = (course) => {
  console.log(data.course.cover_img);

  if (course.cover_img) {
    return BASE_URL + course.cover_img.url;
  }
};
onMounted(async () => {
  let id = router.currentRoute.value.params.id;
  let course = await axios.get(BASE_URL + "/courses/" + id);

  console.log(course.data);
  data.course = course.data;
});
</script>

<style scoped>
.bb-course-overview {
  padding-top: 56px;
  overflow-wrap: anywhere;
}

.bb-course-cover {
  height: 300px;
  position: relative;
  overflow: hidden;
  background: var(--bb-background);
  color: var(--bb-foreground);
  padding: 56px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.bb-course-cover img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bb-course-title {
  text-align: center;
}

.bb-course-body {
  max-width: 600px;
  margin: auto;
  padding: 16px;
  color: var(--bb-foreground);
}

.bb-course-lessons-list-container {
  margin-top: 24px;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 640px) {
  .bb-course-body {
    padding: 16px 0;
  }
}
</style>
