<template>
  <div :style="computedStyle" class="content">
    <vue3-markdown-it
      v-if="data.markdown"
      :html="true"
      :linkify="true"
      :typographer="true"
      :source='data.markdown' />

    <button @click="handleNext" class="next" v-if="data.next">NEXT</button>
  </div>
</template>

<script setup>

import axios from "axios"
import { reactive, computed, onMounted, defineProps } from "vue"
const BASE_URL = process.env.VUE_APP_BASEURL


const data = reactive({
  course: props.course,
  lesson_number: props.course,
  markdown: null,
  next: null,
})

const props = defineProps({
  course: {
    type: String,
  },
  lesson_number: {
    type: String,
  },
  width: {
    type: String,
    default: "50vw"
  },

  disableScroll: {
    type: Boolean,
    default: false
  }
})

const handleNext = async () => {
  data.markdown = null
  data.course = data.next.course.id
  data.lesson_number = data.next.lesson_number

  let lesson = await axios.get(
    `${BASE_URL}/lessons?course=${data.course}&lesson_number=${data.lesson_number}`)

  data.markdown = lesson.data[0].content

  let next = await axios.get(
    `${BASE_URL}/lessons?course=${data.course}&lesson_number=${parseInt(data.lesson_number)+1}`)

  if(next.data) {
    data.next = next.data[0]
  } else {
    data.next = null
  }
}

onMounted(async ()=>{
  let lesson = await axios.get(
    `${BASE_URL}/lessons?course=${props.course}&lesson_number=${props.lesson_number}`)

  data.markdown = lesson.data[0].content

  let next = await axios.get(
    `${BASE_URL}/lessons?course=${data.course}&lesson_number=${parseInt(data.lesson_number)+1}`)

  if(next.data) {
    data.next = next.data[0]
  }

  console.log(data.next)
})

const computedStyle = computed(() => {
  return { width: props.width, userSelect: props.disableScroll }
})

</script>

<style>

code {
  background: var(--bb-background);
    color: var(--bb-foreground);
    border-radius: 2px;
    padding: 0 4px;
}

a {
  text-decoration: underline;
}

th {
  font-size: 14px;
}

td {
  background: var(--bb-background);
  color: var(--bb-foreground);
  padding: 8px !important;
}

button.next {
  border: 1px solid var(--bb-comment);
  background: var(--bb-cyan);
  color: var(--bb-current-line);
}
</style>

