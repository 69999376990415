<template>
  <div class="header" :class="{ scrolled: data.scrolled }">
    <MenuBar :model="data.items">
      <template #start>
        <div class="logo-full">
          <div class="logo">
            <router-link to="/">
              <img src="@/assets/logo.svg" />
            </router-link>
          </div>
          <div class="logo-text">BITBRAIN</div>
        </div>
      </template>
    </MenuBar>
  </div>
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { PrimeIcons } from "primevue/api";

const data = reactive({
  scrolled: false,
  items: [
    { label: "Sandbox", to: "/sandbox", icon: PrimeIcons.BOX },
   // { label: "Codebox", to: "/codebox", icon: PrimeIcons.CODE },
   // { label: "Sockbox", to: "/sockbox", icon: PrimeIcons.DESKTOP },
    { label: "Markdown Editor", to: "/markdown", icon: PrimeIcons.PENCIL },
  ],
});

onMounted(() => {
  window.onscroll = () => {
    if (window.scrollY > 100) {
      data.scrolled = true;
    } else {
      data.scrolled = false;
    }
  };
});
</script>

<style scoped>
.header {
  color: var(--bb-foreground);
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  align-items: center;
}

.scrolled {
  background: linear-gradient(45deg, var(--cyan-500), var(--cyan-600));
  border-bottom: 1px solid #dee2e6;
}

.header-nav {
  display: flex;
  justify-content: flex-end;
}

.header-item {
  margin: 0 12px;
  line-height: 24px;
}

.logo-full {
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: center;
  margin: 0 12px;
}

.header-nav-mobile {
  display: none;
}

@media screen and (max-width: 640px) {
  .logo-text {
    display: none;
  }
  .logo img {
    margin: 0;
  }

  .header-nav {
    display: none;
  }

  .header-nav-mobile {
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;
  }

  .header-item {
    margin: 8px;
    background: var(--bb-background);
    color: var(--bb-green);
  }

  button {
    border: none !important;
    color: var(--bb-green) !important;
  }
}

.header-nav-mobile-menu {
  text-align: right;
  position: absolute;
  height: 100vh;
  width: 50%;
  right: 0;
  padding-top: 16px;
  top: 56px;
  background: var(--bb-background);
  box-sizing: border-box;
}

.header-nav-mobile-toggle {
  transform: rotate(90deg);
}

.logo {
  line-height: 48px;
  height: 48px;
}

.logo-text {
  font-family: system-ui;
  font-size: 24px;
  height: 24px;
  margin-left: 8px;
  font-weight: bold;
  line-height: 24px;
}

.logo img {
  filter: brightness(0.5);
  vertical-align: middle;
  height: 32px;
}

button {
  font-weight: bold;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 12px;
  text-transform: capitalize;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(50%);
  opacity: 0;
}

.p-menubar {
  border-radius: 0;
  padding: 0.5rem;
  background: none;
  color: #fdfdfe;
  border: none;
  /* border-bottom: 1px solid #dee2e6; */
  width: 100%;
  justify-content: space-between;
}
</style>
