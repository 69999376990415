<template>
  <div class="bb-courses">
    <div v-if="data.brainbits" class="bb-courses-list">
      <div class="bit" v-for="bit in data.brainbits" :key="bit">
        <router-link class="bit-data" :to="`/sandbox/${bit.slug}`">
          <div class="id">
            {{ bit.id }} | {{ bit.slug }}
          </div>
          <div class="trunc">
            {{ bit.html}}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios"
import { reactive, onMounted } from "vue"

const BASE_URL = process.env.VUE_APP_BASEURL

const data = reactive({
  brainbits: []
})

onMounted(async ()=>{
  let brainbits = await axios.get(BASE_URL + "/brainbits")

  data.brainbits = brainbits.data
})

</script>

<style scoped>

.bit {
  border: 1px solid black;
}

.bit-data {
  background: var(--bb-orange);
  color: var(--bb-black);
  padding: 16px;
  display: grid;
  font-weight: bold;
  grid-template-columns: 1fr 1fr;
}

.trunc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bb-courses {
  padding: 8px;
}

.bb-courses-list {
  margin: 76px auto;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 400px));
  grid-gap: 8px;
}

@media screen and (max-width: 640px) {
  .bb-courses-list {
    grid-template-columns: repeat(auto-fit, minmax(100%, 400px));
  }
}
</style>

