<template>
  <div :style="computedStyle" class="content">
    <vue3-markdown-it
      v-if="props.markdown"
      :html="true"
      :linkify="true"
      :typographer="true"
      :source="props.markdown"
    />
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  markdown: {
    type: String,
  },

  lesson: {
    type: String,
  },

  width: {
    type: String,
    default: "50vw",
  },

  disableScroll: {
    type: Boolean,
    default: false,
  },
});

const computedStyle = computed(() => {
  return { width: props.width, userSelect: props.disableScroll };
});
</script>

<style scope>
.content {
  margin-top: 65px !important;
  min-width: 120px;
  height: calc(100% - 56px) !important;
  box-sizing: border-box;
  padding: 32px;
  grid-area: content;
  overflow: auto;
}
</style>
