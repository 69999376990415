<template>
  <router-link :to="coursePath" class="bb-course">
    <div v-if="props.course.cover_img" class="bb-course-cover">
      <img :src="getImgUrl(props.course)"/>
    </div>

    <div class="bb-course-title">
      <h3>
        {{ props.course.title}}
      </h3>
    </div>

    <div class="bb-course-description">
      <div class="bb-course-description-text">
        <p>
          {{ props.course.description}}
        </p>
      </div>
    </div>

    <div class="bb-course-lessons">
      <div class="bb-course-lessons-details">
        <b>{{ props.course.lessons.length }}</b> Lesson(s)
      </div>
    </div>
  </router-link>
</template>

<script setup>
import { computed, defineProps } from "vue"
const BASE_URL = process.env.VUE_APP_BASEURL

const props = defineProps({
  course: {
    type: Object,
  }
})

const coursePath = computed(()=>{
  return `/courses/${props.course.id}`
})

const getImgUrl = (course) => {
  if(course.cover_img) {
    return BASE_URL + course.cover_img.url
  }
}

</script>

<style scoped>

.bb-course {
  background: var(--bb-foreground);
  text-align: left;
  max-width: 400px;
  display: grid;
  grid-template:
  "img" min-content
  "title" min-content
  "desc" min-content
  "less" 1fr;
  grid-column-gap: 16px;
  margin: auto auto 16px auto;
  border: 1px solid var(--bb-background);
}

.bb-course-cover {
  position: relative;
  height: 100px;
  overflow: hidden;
  grid-area: img;
  background: var(--bb-background);
}

.bb-course-cover img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.bb-course-title {
  text-align: center;
  padding: 0 16px;
  grid-area: title;
}

.bb-course-description {
  padding: 0 16px;
  grid-area: desc;
}

.bb-course-lessons {
  padding: 0 16px;
  grid-area: less;
  margin-bottom: 16px;
}

.bb-course-lesson-start {
  padding: 16px;
  grid-area: last;
  align-self: self-end;
  justify-self: flex-end;
}

</style>
